import React from "react";
import { Link } from "gatsby";
import kebabCase from "lodash.kebabcase";

export default function ListNote({ edges }) {
  return (
    <>
      {edges.map((edge) => (
        <div className="col-span-12 md:col-span-6 my-2 py-4 md:my-0 border-color-slate-200 border-b last:border-none">
          <div className="items-center uppercase leading-relaxed">
            <time>{edge.node.fields.date}</time>
          </div>
          <Link to={`/${edge.node.fields.slug}`}>
            <h2 className="underline my-2 font-serif">{edge.node.fields.title}</h2>
          </Link>
          <p className="mb-2 text-slate-600">
            {edge.node.excerpt || edge.node.frontmatter.description}
          </p>
          <ul className="flex flex-row gap-4 mb-4">
          {edge.node.frontmatter.tags &&
            edge.node.frontmatter.tags.map((tag) => (
              <li key={tag} className="px-2 py-1 bg-slate-100 rounded-full">
                <Link to={`/tag/${kebabCase(tag)}`}>{tag}</Link>
              </li>
            ))}
        </ul>
        </div>
      ))}
    </>
  );
}
